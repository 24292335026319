import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const isAdmin = localStorage.getItem("admin") === "true";

  // If admin is true, allow access to the route, otherwise redirect to login page
  return isAdmin ? children : <Navigate to="/" />;
};

export default PrivateRoute;
