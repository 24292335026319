import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Typography,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  Chip,
  Box,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the ArrowBack icon
import { useNavigate } from "react-router-dom"; // For navigation

const JobRoleCategeory = () => {
  const [jobRoles, setJobRoles] = useState([]);
  const [currentRoleIndex, setCurrentRoleIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [requirementOptions, setRequirementOptions] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [selectedRequirements, setSelectedRequirements] = useState([]); // For multiple select
  const [errorMessage, setErrorMessage] = useState(""); // For Snackbar error message
  const [openSnackbar, setOpenSnackbar] = useState(false); // To control Snackbar visibility

  const navigate = useNavigate(); // For navigation to the dashboard

  const fetchJobRoles = async () => {
    const usertype = localStorage.getItem("usertype");
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const basicAuth = btoa(`${username}:${password}`);

    const headers = {
      category: usertype,
      status: "categorised",
      Authorization: `Basic ${basicAuth}`,
    };

    try {
      const response = await axios.get(
        "https://management.plugleads.in/role_corpus_data/roledet/get_details/",
        {
          headers,
        }
      );

      console.log("API response data:", response.data);

      if (
        response.data &&
        response.data.role &&
        typeof response.data.role === "object" &&
        !Array.isArray(response.data.role)
      ) {
        setRequirementOptions(
          response.data.requirements && response.data.requirements.length
            ? response.data.requirements.map((req) => req.all_requirements)
            : ["No Requirements"] // Default to "No Requirements" if no data
        );
        const role = {
          id: response.data.role.id,
          title: response.data.role.name || "Job Role",
          description:
            response.data.role.description || "No description provided",
          category: response.data.role.category || "Uncategorized",
          status: response.data.role.status || "Fetched",
        };

        setJobRoles([role]); // Wrap the role in an array
      } else {
        console.error("Unexpected data structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching job roles:", error);
      setErrorMessage("Error fetching job roles. Please try again.");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchJobRoles();
  //   console.log(requirementOptions);
  // }, []);

  const handlesave = async () => {
    await fetchJobRoles();
  };

  const handlefetchrole = async () => {
    await fetchJobRoles();
  };

  const handleNext = async () => {
    let validationErrors = {};

    if (selectedRequirements.length === 0) {
      validationErrors.selectedRequirements =
        "Please select at least one requirement.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setFieldErrors(validationErrors);
      return;
    }

    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const userid = localStorage.getItem("userid");

    const requestData = {
      id: jobRoles[currentRoleIndex]?.id,
      role_requirements: selectedRequirements,
      username: username,
      userid: userid,
    };

    try {
      const response = await axios.post(
        "https://management.plugleads.in/role_corpus_data/update-role/Mapping/",
        requestData,
        {
          auth: {
            username: `${username}`,
            password: `${password}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Data posted successfully:", response.data);

        setSelectedRequirements([]);

        if (currentRoleIndex < jobRoles.length - 1) {
          setCurrentRoleIndex((prevIndex) => prevIndex + 1);
          setFieldErrors({});
        } else {
          console.log("No more job roles available.");
        }
      }
    } catch (error) {
      console.error("Error posting data:", error);
      setErrorMessage("There was an error posting the data. Please try again.");
      setOpenSnackbar(true);
    }
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const filteredValues = value.filter((v) => v !== "Select Job Requirement");
    setSelectedRequirements(filteredValues);

    if (filteredValues.length > 0) {
      setFieldErrors((prev) => ({ ...prev, selectedRequirements: "" }));
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleBackToDashboard = () => {
    navigate("/dashboard"); // Navigate to the dashboard route
  };

  if (currentRoleIndex >= jobRoles.length) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            height: "fit-content",
            gap: "20px",
          }}
        >
          <IconButton
            onClick={() => navigate("/dashboard")}
            color="primary"
            style={{ marginBottom: "0px", fontSize: "23px" }}
            sx={{
              "&:hover": {
                backgroundColor: "transparent", // Disables hover background
              },
            }}
          >
            <ArrowBackIcon /> Back To Dashboard
          </IconButton>{" "}
          <Button
            variant="outlined"
            color="primary"
            onClick={handlefetchrole}
            sx={{ textTransform: "none", padding: "2px" }}
          >
            Fetch Job Role
          </Button>
        </Box>
      </div>
    );
  }

  const currentRole = jobRoles[currentRoleIndex];

  return (
    <div style={{ margin: "auto" }}>
      {/* Back to Dashboard Button */}
      <IconButton
        onClick={() => navigate("/dashboard")}
        color="primary"
        style={{ marginBottom: "20px", fontSize: "23px" }}
        sx={{
          "&:hover": {
            backgroundColor: "transparent", // Disables hover background
          },
        }}
      >
        <ArrowBackIcon /> Back To Dashboard
      </IconButton>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "auto",
          alignItems: "center",
          justifySelf: "center",
          width: "70%",
        }}
      >
        <Box sx={{ width: "50%", padding: "20px" }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontSize: "40px", fontWeight: "900px", color: "DarkBlue" }}
          >
            {currentRole.title}
          </Typography>
        </Box>

        <Box sx={{ width: "50%", padding: "20px" }}>
          <Typography
            sx={{ marginTop: "20px", marginBottom: "-15px", fontSize: "14px" }}
          >
            Select The Job Requirement
          </Typography>
          <FormControl fullWidth style={{ marginTop: "20px" }}>
            <Select
              multiple
              value={selectedRequirements}
              onChange={handleSelectChange}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {requirementOptions.map((requirement, index) => (
                <MenuItem key={requirement} value={requirement}>
                  <Checkbox
                    checked={selectedRequirements.includes(requirement)}
                  />

                  <ListItemText primary={requirement} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div
            style={{
              marginTop: "20px",
              textAlign: "right",
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <Button variant="contained" color="primary" onClick={handleNext}>
              Save
            </Button>
            <Button variant="outlined" color="primary" onClick={handlesave}>
              Next
            </Button>
          </div>
        </Box>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default JobRoleCategeory;
