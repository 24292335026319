import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Typography,
  CircularProgress,
  Box,
  TextField,
  Button,
  Alert,
  Divider,
  IconButton,
  Snackbar,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom"; // React Router hook for navigation

const JobRoleQuestions = () => {
  const [jobRoles, setJobRoles] = useState([]);
  const [currentRoleIndex, setCurrentRoleIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [requirements, setRequirements] = useState([]);
  const [error, setError] = useState("");
  const [questions, setQuestions] = useState([]); // Array of question strings
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  }); // Snackbar state
  const navigate = useNavigate(); // React Router navigation hook

  const fetchJobRoles = async () => {
    const usertype = localStorage.getItem("usertype");
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const basicAuth = btoa(`${username}:${password}`);

    const headers = {
      category: usertype,
      status: "questions_pending",
      Authorization: `Basic ${basicAuth}`,
    };

    try {
      const response = await axios.get(
        "https://management.plugleads.in/role_corpus_data/roledet/get_details/",
        { headers }
      );

      if (
        response.data &&
        typeof response.data === "object" &&
        !Array.isArray(response.data)
      ) {
        setRequirements(
          response.data.role && response.data.role.role_requirements
            ? response.data.role.role_requirements
            : ["No Role Requirements Available"] // Default message if no role requirements
        );
        const role = {
          id: response.data.role.id,
          title: response.data.role.name || "Job Role",
          description: response.data.description || "No description provided",
          category: response.data.category || "Uncategorized",
          category: response.data.category || "Uncategorized",
          status: response.data.status || "Fetched",
        };
        setJobRoles([role]);
      } else {
        console.error("Unexpected data structure:", response.data);
        setSnackbar({
          open: true,
          message: "Unexpected data structure returned.",
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching job roles:", error);
      setSnackbar({
        open: true,
        message: "Error fetching job roles. Please try again later.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handlefetchrole = async () => {
    await fetchJobRoles();
  };

  // useEffect(() => {
  //   fetchJobRoles();
  // }, []);

  const handleNext = async () => {
    if (questions.length === 0) {
      setError("Questions are required before proceeding.");
      setSnackbar({
        open: true,
        message: "Questions are required.",
        severity: "error",
      });
      return;
    }

    const currentRole = jobRoles[currentRoleIndex];
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const userid = localStorage.getItem("userid");

    const basicAuth = btoa(`${username}:${password}`);

    const requestData = {
      id: currentRole.id,
      username: username,
      userid: userid,
      questions: questions, // Send the array of question strings
    };

    try {
      const response = await axios.post(
        "https://management.plugleads.in/role_corpus_data/questions-pending/updatequest/",
        requestData,
        {
          headers: {
            Authorization: `Basic ${basicAuth}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Data posted successfully:", response.data);

      setQuestions([]); // Clear the questions after successful submission
      setSnackbar({
        open: true,
        message: "Questions submitted successfully!",
        severity: "success",
      });

      if (currentRoleIndex < jobRoles.length - 1) {
        setCurrentRoleIndex((prevIndex) => prevIndex + 1);
      } else {
        console.log("No more job roles available.");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      setSnackbar({
        open: true,
        message: "Error submitting questions. Please try again.",
        severity: "error",
      });
    }
  };

  const handlesave = async () => {
    await fetchJobRoles();
  };

  const handleAddQuestion = () => {
    setQuestions((prevQuestions) => [...prevQuestions, ""]);
    setError("");
  };

  const handleQuestionChange = (index, value) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question, i) => (i === index ? value : question))
    );
  };

  const handleQuestionRemove = (index) => {
    setQuestions((prevQuestions) =>
      prevQuestions.filter((_, i) => i !== index)
    );
  };

  const handleBackToDashboard = () => {
    navigate("/dashboard"); // Adjust the route as per your application
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const currentRole = jobRoles[currentRoleIndex] || null;

  return (
    <Box style={{ padding: "10px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          height: "fit-content",
          gap: "20px",
        }}
      >
        <IconButton
          onClick={() => navigate("/dashboard")}
          color="primary"
          style={{ marginBottom: "0px", fontSize: "23px" }}
          sx={{
            "&:hover": {
              backgroundColor: "transparent", // Disables hover background
            },
          }}
        >
          <ArrowBackIcon /> Back To Dashboard
        </IconButton>{" "}
        <Button
          variant="outlined"
          color="primary"
          onClick={handlefetchrole}
          sx={{ textTransform: "none", padding: "2px" }}
        >
          Fetch Job Role
        </Button>
      </Box>
      {currentRole && (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "100%",
            gap: "20px",
            margin: "auto",
          }}
        >
          {/* Left Box */}
          <Box sx={{ width: "30%", padding: "20px" }}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: "33px", fontWeight: "900px", color: "DarkBlue" }}
            >
              {currentRole.title}
            </Typography>
          </Box>

          {/* Middle Box */}
          <Box
            sx={{
              width: "30%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              padding: "10px",
            }}
          >
            <Typography
              sx={{
                marginTop: "20px",
                fontSize: "24px",
                fontWeight: "bold",
                marginBottom: "-5px",
              }}
            >
              Requirements
            </Typography>
            <Box sx={{ marginTop: "0" }}>
              {requirements && requirements.length > 0 ? (
                <ul style={{ paddingLeft: "20px" }}>
                  {requirements.map((requirement, index) => (
                    <li
                      key={index}
                      style={{ marginBottom: "10px", fontSize: "16px" }}
                    >
                      {requirement}
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography sx={{ fontSize: "16px", color: "gray" }}>
                  No requirements available.
                </Typography>
              )}
            </Box>
          </Box>

          {/* Right Box */}
          <Box
            sx={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              padding: "10px",
            }}
          >
            <Typography sx={{ marginTop: "20px", fontSize: "24px" }}>
              Add Questions
            </Typography>
            {error && (
              <Alert severity="error" style={{ marginBottom: "10px" }}>
                {error}
              </Alert>
            )}
            {questions.map((question, index) => (
              <div key={index} style={{ width: "80%" }}>
                <TextField
                  label={`Question ${index + 1}`}
                  variant="outlined"
                  value={question}
                  onChange={(e) => handleQuestionChange(index, e.target.value)}
                  style={{ marginBottom: "10px", width: "80%" }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleQuestionRemove(index)}
                  style={{ marginBottom: "10px", textTransform: "none" }}
                >
                  Remove Question
                </Button>
                <Divider style={{ margin: "20px 0" }} />
              </div>
            ))}
            <Button
              variant="outlined"
              color="primary"
              onClick={handleAddQuestion}
              style={{ marginBottom: "20px", textTransform: "none" }}
            >
              Add Question
            </Button>
            <div
              style={{
                marginTop: "20px",
                textAlign: "right",
                display: "flex",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <Button variant="contained" color="primary" onClick={handleNext}>
                Save
              </Button>
              <Button variant="outlined" color="primary" onClick={handlesave}>
                Next
              </Button>
            </div>
          </Box>
        </Box>
      )}

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={snackbar.message}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor:
              snackbar.severity === "success" ? "green" : "tomato",
          },
        }}
      />
    </Box>
  );
};

export default JobRoleQuestions;
