import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Link,
  Alert,
  IconButton,
  InputAdornment,
  Paper,
} from "@mui/material";
import { Visibility, VisibilityOff, Facebook } from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleInputChange = (setter, setError) => (e) => {
    setter(e.target.value);
    setError(false);
  };

  const handleLogin = async () => {
    if (!email) {
      setEmailError(true);
      setSnackbarMessage("Email is required.");
      return;
    }
    if (!password) {
      setPasswordError(true);
      setSnackbarMessage("Password is required.");
      return;
    }

    try {
      const payload = {
        username: email, // Assuming email is used as the username
        password: password,
      };

      // Check if the login credentials match the admin credentials
      if (
        email === "admin@nexiiconsulting.com" &&
        password === "Password@123"
      ) {
        // Set admin as true in localStorage
        localStorage.setItem("admin", "true");
      }

      // Send POST request with payload
      const response = await axios.post(
        "https://management.plugleads.in/role_corpus_management/auth/login/",
        payload
      );

      if (response.status === 200 && response.data && response.data.user) {
        const { user_type } = response.data.user;
        const { id } = response.data.user;
        if (user_type === "MANAGER") {
          localStorage.setItem("admin", "true");
        }
        localStorage.setItem("usertype", user_type);
        localStorage.setItem("username", email);
        localStorage.setItem("password", password);
        localStorage.setItem("userid", id);

        navigate("/dashboard");
      } else {
        setSnackbarMessage("Invalid credentials. Please try again.");
      }
    } catch (error) {
      setSnackbarMessage(
        error.response?.data?.message ||
          "Something went wrong. Please try again."
      );
    }
  };

  const renderTextField = (
    label,
    value,
    onChange,
    type = "text",
    adornment = null
  ) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginBottom: 2,
      }}
    >
      <Typography variant="body2" sx={{ marginBottom: 1 }}>
        {label}
      </Typography>
      <Box
        component="input"
        value={value}
        onChange={onChange}
        type={type}
        style={{
          padding: "10px",
          border:
            emailError || passwordError ? "1px solid red" : "1px solid #ccc",
          borderRadius: "5px",
          width: "100%",
        }}
        endAdornment={adornment}
      />
    </Box>
  );

  const renderSocialButton = (icon, label) => (
    <Button
      variant="outlined"
      sx={{ display: "flex", alignItems: "center", gap: 1 }}
    >
      {icon}
      {label}
    </Button>
  );

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        background: "linear-gradient(335deg, #514cb0, #3d5cac, #63b1f3)",
      }}
    >
      {/* Right Section */}
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          justifySelf: "center",
          background: "rgb(255,255,255,0.4)",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "20px",
          border: "1px solid deepOrange",
          padding: "30px",
          margin: "auto",
          width: "50%",
          height: "fit-content",
        }}
      >
        <Typography sx={{ color: "white", fontSize: "36px" }}>Login</Typography>
        {/* Email and Password Fields */}
        <Box sx={{ width: "80%", marginBottom: 2 }}>
          {renderTextField(
            "Email",
            email,
            handleInputChange(setEmail, setEmailError)
          )}
          {renderTextField(
            "Password",
            password,
            handleInputChange(setPassword, setPasswordError),
            showPassword ? "text" : "password",
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )}
        </Box>

        <Box sx={{ width: "80%" }}>
          {snackbarMessage && (
            <Alert
              severity="error"
              onClose={() => setSnackbarMessage("")}
              style={{ marginTop: "10px", margin: "20px" }}
            >
              {snackbarMessage}
            </Alert>
          )}
        </Box>

        {/* Login Button */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#FFD700",
            textTransform: "none",
            borderRadius: 5,
            width: "30%",
            p: 1,
            color: "#000",
            fontWeight: "bold",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          onClick={handleLogin}
        >
          Log In
        </Button>
      </Paper>
    </Box>
  );
};

export default Login;
