import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import WorkIcon from "@mui/icons-material/Work";
import GroupIcon from "@mui/icons-material/Group";
import CategoryIcon from "@mui/icons-material/Category";
import QuizIcon from "@mui/icons-material/Quiz";

import VerifiedIcon from "@mui/icons-material/Verified";

const Dashboard = () => {
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const admin = localStorage.getItem("admin");

    const initialCards = [
      {
        title: "Role Type",
        icon: <WorkIcon sx={{ fontSize: 60, color: "#3f51b5" }} />,
        route: "/type",
        adminOnly: true,
      },
      {
        title: "Role Approval",
        icon: <VerifiedIcon sx={{ fontSize: 60, color: "#3f51b5" }} />,
        route: "/jobroleapproval",
        adminOnly: true,
      },
      {
        title: "Role Description",
        icon: <GroupIcon sx={{ fontSize: 60, color: "#3f51b5" }} />,
        route: "/roles",
      },
      {
        title: "Role Requirements",
        icon: <CategoryIcon sx={{ fontSize: 60, color: "#3f51b5" }} />,
        route: "/category",
      },
      {
        title: "Role Questions",
        icon: <QuizIcon sx={{ fontSize: 60, color: "#3f51b5" }} />,
        route: "/questions",
      },
    ];

    // Filter cards based on admin status
    const filteredCards = admin
      ? initialCards // Admin gets all cards
      : initialCards.filter((card) => !card.adminOnly); // Non-admins see only non-restricted cards

    setCards(filteredCards);
  }, []);

  return (
    <Box
      sx={{
        padding: 4,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "linear-gradient(135deg, #ece9e6, #ffffff)",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          color: "#3f51b5",
          marginBottom: 5,
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
        }}
      >
        Welcome to the Dashboard
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "20px",
          alignItems: "center",
        }}
      >
        {localStorage.getItem("admin") && (
          <Button
            variant="outlined"
            sx={{
              background: "lightorange",
              textTransform: "none",
              color: "#000",
            }}
            onClick={() => {
              navigate("/admindashboard");
            }}
          >
            Create New User
          </Button>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            localStorage.clear();
            navigate("/");
          }}
          startIcon={<LogoutIcon />}
          sx={{
            textTransform: "none",
            padding: "8px 16px",
          }}
        >
          Log Out
        </Button>
      </Box>

      <Grid container spacing={4} justifyContent="center" mt={2}>
        {cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card
              sx={{
                minHeight: 220,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                borderRadius: "12px",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0 12px 24px rgba(0, 0, 0, 0.3)",
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {card.icon}
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{
                    fontWeight: "bold",
                    color: "#3f51b5",
                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
                    marginTop: 1,
                    mb: 1,
                  }}
                >
                  {card.title}
                </Typography>
              </CardContent>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(card.route)}
                sx={{
                  marginBottom: 2,
                  background: "linear-gradient(45deg, #3f51b5, #1e88e5)",
                  color: "#fff",
                  fontWeight: "bold",
                  borderRadius: "20px",
                  textTransform: "none",
                  transition: "background 0.3s",
                  "&:hover": {
                    background: "linear-gradient(45deg, #1e88e5, #3f51b5)",
                  },
                }}
              >
                Go to {card.title}
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Dashboard;
