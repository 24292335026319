import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Typography,
  Paper,
  Select,
  FormControl,
  MenuItem,
  Button,
  TextField,
  CircularProgress,
  IconButton,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Importing back icon
import { useNavigate } from "react-router-dom"; // React Router for navigation
import DeleteIcon from "@mui/icons-material/Delete";

const JobRolesTable = () => {
  const [jobRoles, setJobRoles] = useState([]);
  const [currentRoleIndex, setCurrentRoleIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(""); // Error message state
  const [jobType, setJobType] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [roleId, setRoleId] = useState("");
  const [mandatoryRequirements, setMandatoryRequirements] = useState([]);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [additionalRequirements, setAdditionalRequirements] = useState([]);
  const navigate = useNavigate();

  const fetchJobRoles = async () => {
    const usertype = localStorage.getItem("usertype");
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const basicAuth = btoa(`${username}:${password}`);

    const headers = {
      category: usertype,
      status: "categorised",
      Authorization: `Basic ${basicAuth}`,
    };

    try {
      const response = await axios.get(
        "https://management.plugleads.in/role_corpus_data/roledet/get_details/",
        { headers }
      );

      if (
        response.data &&
        typeof response.data === "object" &&
        !Array.isArray(response.data)
      ) {
        const role = {
          id: response.data.role.id,
          title: response.data.role.name || "Job Role",
          description:
            response.data.role.description || "No description provided",
          category: response.data.role.category || "Uncategorized",
          status: response.data.status || "Fetched",
        };

        setJobRoles([role]);
        setRoleId(role.id);
        setMandatoryRequirements(response.data.requirements || []);
      } else {
        console.error("Unexpected data structure:", response.data);
      }
    } catch (error) {
      handleError("Error fetching job roles. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleError = (message) => {
    setError(message);

    // Clear the error after 5 seconds
    setTimeout(() => {
      setError("");
    }, 5000);
  };

  // useEffect(() => {
  //   fetchJobRoles();
  // }, []);

  const validateRequirements = () => {
    let isValid = true;
    const errors = {};

    if (!description.trim()) {
      setDescriptionError("Description cannot be empty.");
      isValid = false;
    } else {
      setDescriptionError("");
    }

    additionalRequirements.forEach((req, index) => {
      if (!req.trim()) {
        errors[index] = "This field cannot be empty.";
        isValid = false;
      }
    });

    setFieldErrors(errors);
    return isValid;
  };

  const handleNext = async () => {
    if (!validateRequirements()) {
      handleError("Please resolve the errors before proceeding.");
      return;
    }

    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const userid = localStorage.getItem("userid");
    const basicAuth = btoa(`${username}:${password}`);
    const requestData = {
      role_id: roleId, // Role ID to be sent
      requirements: additionalRequirements, // Additional requirements state
      description: description, // Description to be sent
      username,
      userid,
    };

    const headers = {
      Authorization: `Basic ${basicAuth}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(
        "https://management.plugleads.in/role_corpus_data/roledet/update_desc/",
        requestData,
        { headers }
      );

      setMandatoryRequirements([]);
      setDescription("");
      setAdditionalRequirements([]);
      setJobType("");
      setFieldErrors({});
      setDescriptionError("");

      setLoading(true);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        handleError(error.response.data.message);
      } else {
        handleError("There was an error posting the data. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handlesave = async () => {
    await fetchJobRoles();
  };
  const handlefetchrole = async () => {
    await fetchJobRoles();
  };

  const handleAddRequirement = () => {
    setAdditionalRequirements([...additionalRequirements, ""]);
    setFieldErrors({});
  };

  const handleRemoveRequirement = (index) => {
    const updatedRequirements = additionalRequirements.filter(
      (_, i) => i !== index
    );
    setAdditionalRequirements(updatedRequirements);

    const updatedErrors = { ...fieldErrors };
    delete updatedErrors[index];
    setFieldErrors(updatedErrors);
  };

  const handleAdditionalRequirementChange = (index, value) => {
    const updatedRequirements = additionalRequirements.map((req, i) =>
      i === index ? value : req
    );
    setAdditionalRequirements(updatedRequirements);

    if (value.trim()) {
      const updatedErrors = { ...fieldErrors };
      delete updatedErrors[index];
      setFieldErrors(updatedErrors);
    }
  };

  const currentRole = jobRoles[currentRoleIndex] || null;

  return (
    <div style={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          height: "fit-content",
          gap: "20px",
        }}
      >
        <IconButton
          onClick={() => navigate("/dashboard")}
          color="primary"
          style={{ marginBottom: "0px", fontSize: "23px" }}
          sx={{
            "&:hover": {
              backgroundColor: "transparent", // Disables hover background
            },
          }}
        >
          <ArrowBackIcon /> Back To Dashboard
        </IconButton>{" "}
        <Button
          variant="outlined"
          color="primary"
          onClick={handlefetchrole}
          sx={{ textTransform: "none", padding: "2px" }}
        >
          Fetch Job Role
        </Button>
      </Box>
      {error && (
        <Typography
          variant="body1"
          color="error"
          style={{ marginBottom: "10px" }}
        >
          {error}
        </Typography>
      )}

      {currentRole && (
        <Box
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-evenly",
            justifySelf: "center",
          }}
        >
          {/* Job Title */}
          <Box sx={{ width: "30%" }}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ color: "darkgreen", fontWeight: "bold" }}
            >
              <strong style={{ color: "blue" }}>Job Role: </strong>
              {currentRole.title}
            </Typography>
            <Typography
              variant="body1"
              style={{ marginTop: "5px", fontSize: "20px" }}
            >
              <strong style={{ marginBottom: "0px" }}>Requirements:</strong>
              {/* Map through the requirements array */}
              {mandatoryRequirements.length > 0 ? (
                <ul style={{ marginTop: "1px" }}>
                  {mandatoryRequirements.map((req, index) => (
                    <li key={index}>{req.all_requirements}</li>
                  ))}
                </ul>
              ) : (
                <Typography variant="body2">
                  No requirements available
                </Typography>
              )}
            </Typography>
          </Box>

          {/* Description & Additional Requirements */}
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
            }}
          >
            <Typography variant="h6" style={{ marginTop: "20px" }}>
              Job Description
            </Typography>
            <TextField
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="outlined"
              error={!!descriptionError}
              helperText={descriptionError}
              style={{ marginTop: "10px" }}
            />
            <Typography variant="h6" style={{ marginTop: "20px" }}>
              Additional Requirements
            </Typography>
            {additionalRequirements.map((req, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  width: "100%",
                }}
              >
                <TextField
                  fullWidth
                  value={req}
                  onChange={(e) =>
                    handleAdditionalRequirementChange(index, e.target.value)
                  }
                  variant="outlined"
                  error={!!fieldErrors[index]}
                  helperText={fieldErrors[index] || ""}
                />
                <IconButton
                  color="error"
                  onClick={() => handleRemoveRequirement(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddRequirement}
              style={{ marginTop: "10px" }}
            >
              Add Requirement
            </Button>
            <div
              style={{
                marginTop: "20px",
                textAlign: "right",
                display: "flex",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <Button variant="contained" color="primary" onClick={handleNext}>
                Save
              </Button>
              <Button variant="outlined" color="primary" onClick={handlesave}>
                Next
              </Button>
            </div>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default JobRolesTable;
