import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Select,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Container,
  Typography,
  Grid,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import axios from "axios";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

const JobRoleApproval = () => {
  const navigate = useNavigate();
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [jobRoles, setJobRoles] = useState([]);

  const fetchJobRoles = async () => {
    const usertype = localStorage.getItem("usertype");
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const basicAuth = btoa(`${username}:${password}`);

    const headers = {
      category: usertype,
      status: "approval_pending",
      Authorization: `Basic ${basicAuth}`,
    };

    try {
      const response = await axios.get(
        "https://management.plugleads.in/role_corpus_data/roledet/get_details/",
        { headers }
      );

      if (
        response.data &&
        typeof response.data === "object" &&
        !Array.isArray(response.data)
      ) {
        const role = {
          id: response.data.role.id,
          name: response.data.role.name || "Job Role",
          description: response.data.description || "No description provided",
          category: response.data.category || "Uncategorized",
          status: response.data.status || "Fetched",
        };
        setJobRoles([role]);
      } else {
        console.error("Unexpected data structure:", response.data);
        setSnackbar({
          open: true,
          message: "Unexpected data structure returned.",
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching job roles:", error);
      setSnackbar({
        open: true,
        message: "Error fetching job roles. Please try again later.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobRoles();
  }, []);

  const handleApprove = () => {
    setIsApproved(true);
    setSnackbar({
      open: true,
      message: "Job role approved!",
      severity: "success",
    });
  };

  const handleReject = () => {
    setIsRejected(true);
  };

  const handleBackClick = () => {
    navigate("/dashboard");
  };

  const handleRejectionReasonChange = (event) => {
    setRejectionReason(event.target.value);
  };

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleSubmitRejection = async () => {
    setLoading(true);
    setErrorMessage("");

    try {
      const response = await axios.post(
        "https://your-api-endpoint.com/reject-job-role", // Replace with your API endpoint
        {
          jobRoleId: jobRoles[0]?.id,
          rejectionReason,
          selectedSection,
        }
      );

      setIsRejected(false);
      setSnackbar({
        open: true,
        message: "Rejection submitted successfully!",
        severity: "success",
      });
      setRejectionReason("");
      setSelectedSection("");
      console.log("Rejection submitted successfully:", response.data);
    } catch (error) {
      console.error("Error submitting rejection:", error);
      setErrorMessage("Failed to submit rejection. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (!jobRoles.length) {
    return <div>Loading...</div>;
  }

  const jobRole = jobRoles[0];

  return (
    <Container
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box sx={{ width: "100%", padding: "20px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "start",
          }}
        >
          <IconButton onClick={handleBackClick} color="primary">
            <ArrowBack />
          </IconButton>
          <Typography variant="h4" gutterBottom>
            Job Role Details
          </Typography>
        </Box>
        <Grid container spacing={3} mt={1}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              backgroundColor: "#f2f3f4",
              borderRadius: "30px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              gap: 2,
              height: "80vh",
            }}
          >
            <Typography variant="h6" sx={{ fontSize: "26px" }}>
              <strong>Name:</strong> {jobRole.name}
            </Typography>
            <Typography variant="body1" fontSize={"22px"}>
              <strong>Description:</strong> {jobRole.description}
            </Typography>
            <Typography variant="body1" fontSize={"22px"}>
              <strong>Category:</strong> {jobRole.category}
            </Typography>
            <Typography variant="body1" fontSize={"22px"}>
              <strong>Status:</strong> {jobRole.status}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApprove}
              disabled={isApproved}
              fullWidth
              sx={{ marginBottom: 2 }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleReject}
              disabled={isRejected}
              fullWidth
            >
              Reject
            </Button>
            {isRejected && (
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Rejection Reason"
                      variant="outlined"
                      value={rejectionReason}
                      onChange={handleRejectionReasonChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Select Section</InputLabel>
                      <Select
                        value={selectedSection}
                        onChange={handleSectionChange}
                      >
                        <MenuItem value={1}>Section 1</MenuItem>
                        <MenuItem value={2}>Section 2</MenuItem>
                        <MenuItem value={3}>Section 3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleSubmitRejection}
                  disabled={loading}
                  sx={{ mt: 3 }}
                >
                  {loading ? "Submitting..." : "Submit Rejection"}
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>

        {errorMessage && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            {errorMessage}
          </Typography>
        )}

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbar.message}
        />
      </Box>
    </Container>
  );
};

export default JobRoleApproval;
