import React, { useEffect, useState } from "react";
import {
  Typography,
  FormControl,
  Select,
  Box,
  CircularProgress,
  MenuItem,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the ArrowBack icon
import { useNavigate } from "react-router-dom";

const JobTypeSelector = () => {
  const [jobType, setJobType] = useState("");
  const navigate = useNavigate();
  const jobTypeOptions = ["WHITE", "GREY", "BLUE"]; // Job type options
  const [fieldErrors, setFieldErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [jobRoles, setJobRoles] = useState([]);
  const [currentRoleIndex, setCurrentRoleIndex] = useState(0);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [openDialog, setOpenDialog] = useState(false); // State to control the confirmation dialog

  const fetchJobRoles = async () => {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const basicAuth = btoa(`${username}:${password}`);

    try {
      const response = await axios.get(
        "https://management.plugleads.in/role_corpus_data/random-role/get_role/",
        {
          headers: {
            Authorization: `Basic ${basicAuth}`,
          },
        }
      );

      if (
        response.data &&
        typeof response.data === "object" &&
        !Array.isArray(response.data)
      ) {
        const role = {
          id: response.data.id,
          title: response.data.name || "Job Role",
          description: response.data.description || "No description provided",
          category: response.data.category || "Uncategorized",
          status: response.data.status || "Fetched",
        };

        setJobRoles([role]);
      } else {
        console.error("Unexpected data structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching job roles:", error);
    } finally {
      setLoading(false);
    }
  };

  const unassignRole = async () => {
    const currentRole = jobRoles[currentRoleIndex] || {};
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const basicAuth = btoa(`${username}:${password}`);

    if (currentRole && currentRole.id) {
      try {
        const response = await axios.post(
          "https://management.plugleads.in/role_corpus_data/random-role/unassign_role/",
          {
            role_id: currentRole.id,
          },
          {
            headers: {
              Authorization: `Basic ${basicAuth}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          console.log("Role unassigned successfully");
        } else {
          console.error("Failed to unassign role:", response.status);
        }
      } catch (error) {
        console.error("Error unassigning role:", error);
      }
    }
  };

  const handleJobTypeChange = (event) => {
    const selectedJobType = event.target.value;
    setJobType(selectedJobType);
    setFieldErrors((prev) => ({ ...prev, jobType: "" }));
  };

  const validateSelection = () => {
    if (!jobType) {
      setFieldErrors((prev) => ({
        ...prev,
        jobType: "Please select a job type.",
      }));
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const isValid = validateSelection();
    if (!isValid) return;

    const currentRole = jobRoles[currentRoleIndex] || {};
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const userid = localStorage.getItem("userid");
    const payload = {
      role_id: currentRole.id,
      category: jobType,
      username: username,
      userid: userid,
    };
    const basicAuth = btoa(`${username}:${password}`);

    try {
      const response = await axios.post(
        "https://management.plugleads.in/role_corpus_data/random-role/update_category/",
        payload,
        {
          headers: {
            Authorization: `Basic ${basicAuth}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("Submission response:", response.data);
        setSnackbar({
          open: true,
          message: "Job type and role successfully submitted!",
          severity: "success",
        });
        fetchJobRoles();
      } else {
        console.error("Unexpected response status:", response.status);
        setSnackbar({
          open: true,
          message: "Something went wrong. Please try again.",
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      setSnackbar({
        open: true,
        message: "Error submitting data. Please try again.",
        severity: "error",
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: "", severity: "" });
  };

  const handleDialogClose = (confirmed) => {
    if (confirmed) {
      unassignRole(); // Only unassign role if the user confirmed
    }
    setOpenDialog(false); // Close the dialog either way
  };

  const handleBeforeUnload = (event) => {
    event.preventDefault(); // Prevent the default reload behavior
    setOpenDialog(true); // Open the confirmation dialog
  };

  useEffect(() => {
    fetchJobRoles();

    // Add event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Cleanup event listener
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []); // Empty dependency array ensures this runs only once on mount

  const currentRole = jobRoles[currentRoleIndex] || null;

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <CircularProgress />
        <Typography variant="h6" style={{ marginTop: "10px" }}>
          Fetching Job Roles...
        </Typography>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          width: "50%",
          height: "50vh",
          margin: "auto",
          justifyContent: "center",
          alignItems: "start",
          background: "#f2f3f4",
          borderRadius: "30px",
          border: "2px dashed tomato",
        }}
      >
        <IconButton
          onClick={() => navigate("/dashboard")}
          color="primary"
          style={{ marginBottom: "20px", fontSize: "18px" }}
          sx={{
            "&:hover": {
              backgroundColor: "transparent", // Disables hover background
            },
          }}
        >
          <ArrowBackIcon /> Back To Dashboard
        </IconButton>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "darkgreen", fontWeight: "bold", fontSize: "34px" }}
        >
          <strong style={{ color: "blue" }}>Job Role: </strong>
          {currentRole ? currentRole.title : "No job role available"}
        </Typography>
        <Typography
          sx={{
            marginTop: "20px",
            marginBottom: "-15px",
            fontSize: "14px",
          }}
        >
          Select The Job Type
        </Typography>
        <FormControl fullWidth style={{ marginTop: "20px" }}>
          <Select
            value={jobType}
            onChange={handleJobTypeChange}
            variant="outlined"
            displayEmpty
            error={!!fieldErrors.jobType}
            onBlur={validateSelection}
          >
            <MenuItem value="" disabled>
              Select a job type
            </MenuItem>
            {jobTypeOptions.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
          {fieldErrors.jobType && (
            <Typography
              variant="caption"
              color="error"
              style={{ marginTop: "5px" }}
            >
              {fieldErrors.jobType}
            </Typography>
          )}
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ margin: "auto" }}
        >
          Submit
        </Button>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Role Unassign</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to unassign the role? You will lose unsaved
            data.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            No
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default JobTypeSelector;
