import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import JobRolesTable from "./components/JobRolesTable";
import JobRoleCategeory from "./components/JobRoleCategeory";
import JobRoleQuestions from "./components/JobRoleQuestions";
import Login from "./components/Auth/Login";
import Dashboard from "./Dashboard";
import JobTypeSelector from "./components/JobTypeSelector";
import AdminDashboard from "./components/Admin/AdminDashboard";
import PrivateRoute from "./PrivateRoute";
import JobRoleApproval from "./components/Admin/JobRoleApproval";

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/admindashboard"
            element={
              <PrivateRoute>
                <AdminDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/jobroleapproval"
            element={
              <PrivateRoute>
                <JobRoleApproval />
              </PrivateRoute>
            }
          />
          <Route
            path="/type"
            element={
              <PrivateRoute>
                <JobTypeSelector />
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/roles" element={<JobRolesTable />} />
          <Route path="/category" element={<JobRoleCategeory />} />
          <Route path="/questions" element={<JobRoleQuestions />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
