import React, { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Snackbar,
  IconButton,
  Alert,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const AdminDashboard = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("user");
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleUserTypeChange = (e) => setUserType(e.target.value);

  const validateForm = () => email && password && userType;

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setSnackbar({
        open: true,
        message: "Please fill in all fields.",
        severity: "error",
      });
      return;
    }

    setLoading(true);

    const userData = {
      username: email,
      password,
      email,
      user_type: userType.toUpperCase(),
    };

    let basicAuthCredentials;

    if (userType === "MANAGER") {
      basicAuthCredentials = "admin@nexiiconsulting.com:Password@123";
    } else {
      const loginemail = localStorage.getItem("username");
      const password = localStorage.getItem("password");
      basicAuthCredentials = `${loginemail}:${password}`;
    }

    const encodedCredentials = btoa(basicAuthCredentials);

    try {
      const response = await axios.post(
        "https://management.plugleads.in/role_corpus_management/users/",
        userData,
        {
          headers: {
            Authorization: `Basic ${encodedCredentials}`,
          },
        }
      );
      console.log("User created successfully:", response.data);
      setSnackbar({
        open: true,
        message: "User created successfully!",
        severity: "success",
      });
      setEmail("");
      setPassword("");
      setUserType("user");
      setRole("");
    } catch (err) {
      console.error("Error creating user:", err);
      setSnackbar({
        open: true,
        message: "Error creating user. Please try again.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          margin: "auto",
          padding: "20px",
          borderRadius: "30px",
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifySelf: "center",
          alignSelf: "center",
          justifyContent: "center",
          alignItems: "center",
          background: "#f2f3f4",
          margin: "auto",
          border: "2px  dashed tomato",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Admin Dashboard
        </Typography>
        <IconButton
          onClick={() => navigate("/dashboard")}
          color="primary"
          style={{ marginBottom: "20px", fontSize: "23px" }}
          sx={{
            "&:hover": {
              backgroundColor: "transparent", // Disables hover background
            },
          }}
        >
          <ArrowBackIcon /> Back To Dashboard
        </IconButton>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Employe Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            label=" Employe Password"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>User Type</InputLabel>
            <Select
              value={userType}
              onChange={handleUserTypeChange}
              label="User Type"
            >
              <MenuItem value="grey">Grey Collar</MenuItem>
              <MenuItem value="white">White Collar</MenuItem>
              <MenuItem value="blue">Blue Collar</MenuItem>
              <MenuItem value="MANAGER">Manager</MenuItem>
            </Select>
          </FormControl>

          <Box sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              sx={{ marginTop: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : "Add User"}
            </Button>
          </Box>
        </form>

        {/* Snackbar for alerts */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000} // Set the duration to 5 seconds
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </div>
  );
};

export default AdminDashboard;
